import React, { useState, useEffect } from "react";
import { Button, Dropdown, Spinner, Alert } from "react-bootstrap";
import ReactGA from 'react-ga4';
const monthNames = [
  'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
];

const TopCardIssuersoftheMonth = (props) => {

  const selectedCardType = props.selectedCardType;
  const [selectedBankTypeFilter, setSelectedBankTypeFilter] = useState('Private Sector Banks');
  const [currentMonth, setCurrentMonth] = useState('Dec-24');
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      setError(null);
      try {
        const response = await fetch(`https://yieldlab.ai:9000/api/getTopCardIssuersoftheMonth?cardType=${selectedCardType}&bankType=${selectedBankTypeFilter}&currentMonth=${currentMonth}`);
        if (!response.ok) {
          throw new Error("Network response was not ok.");
        }
        const result = await response.json();
        setData(result);
      } catch (error) {
        console.error("Error fetching data:", error);
        setError("Error fetching data.");
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [currentMonth, selectedBankTypeFilter, selectedCardType]);

  const handleFilterChange = (filter) => {
    setSelectedBankTypeFilter(filter);
    ReactGA.event({
      category: 'Top Card Issuers of the Month',
      action: 'Click',
      label: selectedCardType + ' Card' + filter,
    });
  };

  const handleMonthChange = (direction) => {
    const [month, year] = currentMonth.split('-');
    let monthIndex = monthNames.indexOf(month);
    let newMonthIndex = monthIndex;
    let newYear = year;

    if (direction === 'prev') {
      newMonthIndex--;
      if (newMonthIndex < 0) {
        newMonthIndex = 11;
        newYear = (parseInt(year, 10) - 1).toString().slice(-2); // Handle year change
      }
    } else if (direction === 'next') {
      newMonthIndex++;
      if (newMonthIndex > 11) {
        newMonthIndex = 0;
        newYear = (parseInt(year, 10) + 1).toString().slice(-2); // Handle year change
      }
    }

    const newMonth = `${monthNames[newMonthIndex]}-${newYear}`;
    setCurrentMonth(newMonth);
  };

  const formatDate = (date) => {
    return date; // Already in 'MMM-YY' format
  };

  const formatValue = (value) => {
    // Convert to number and fix to two decimal places
    const number = parseFloat(value).toFixed(2);

    // Split integer and decimal parts
    const [integerPart, decimalPart] = number.split('.');

    // Regex to format integer part for Indian numbering system
    const integerPartFormatted = integerPart
      .replace(/(\d)(?=(\d\d)+\d\b)/g, '$1,'); // For grouping thousands and lakhs

    return `${integerPartFormatted}`;
  };

  return (
    <div className="container mt-7">
      <div className="row pt-4 pb-4">
        <div className="col-12 col-lg-8 d-flex">
          <h4>Top Card Issuers of the Month</h4>
        </div>
        <div className="col-12 col-lg-4 d-flex align-items-center justify-content-lg-end">
          <div className="d-flex flex-wrap gap-2">
            <Dropdown className="mr-05 rounded-pill">
              <Dropdown.Toggle variant="primary btn-sm" id="dropdown-basic">
                {selectedBankTypeFilter}
              </Dropdown.Toggle>
              <Dropdown.Menu>
                {['Foreign Banks', 'Payment Banks', 'Private Sector Banks', 'Public Sector Banks', 'Small Finance Banks'].map((filter, index) => (
                  <Dropdown.Item
                    key={index}
                    onClick={() => handleFilterChange(filter)}
                    className={filter === selectedBankTypeFilter ? 'selected-option' : ''}
                  >
                    {filter}
                  </Dropdown.Item>
                ))}
              </Dropdown.Menu>
            </Dropdown>
            <div>
              <Button className="btn btn-outline-primary btn-light btn-sm credit-card-btn" onClick={() => handleMonthChange('prev')}>
                <i className="fa-solid fa-chevron-left"></i>
              </Button>
              <Button className="btn btn-outline-primary btn-light rounded-0 btn-sm">
                <i className="fa-regular fa-calendar-days"></i> {formatDate(currentMonth)}
              </Button>
              <Button className="btn btn-outline-primary btn-light btn-sm debit-card-btn border-start-0" onClick={() => handleMonthChange('next')}>
                <i className="fa-solid fa-chevron-right"></i>
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-4">
        {loading && <div className="text-center"><Spinner animation="border" /></div>}
        {error && <div className="text-center"><Alert variant="danger">{error}</Alert></div>}
        {!loading && !error && data.length > 0 ? (
          data.map((issuer, index) => (
            <div className="col-lg-4 p-2" key={index}>
              <div className={`card top-sec top-bg-color-${index + 1}`}>
                <div className="m-3">
                  <span className="mr-2"><small>{issuer.entityName}</small></span>
                  <span><small className="up-down-btn">
                    {issuer?.percentage > 0 ? (
                      <b>
                        <i className="fa-solid fa-arrow-up"></i> {issuer?.percentage}%
                      </b>
                    ) : (
                      <b>
                        <i className="fa-solid fa-arrow-down"></i> {issuer?.percentage}%
                      </b>
                    )}
                    </small>
                  </span>
                  <h6><b>{formatValue(issuer.value)}</b></h6>
                  
                </div>
                <div>
                  <img src={`https://yieldlab.ai/asset/images/top-${index + 1}.svg`} alt="Top Card Issuers" className="img-fluid" />
                </div>
              </div>
            </div>
          ))
        ) : (
          <div className="text-center no-data-container">
            <div className="mixed-chart text-center p-3">
              <img src="https://yieldlab.ai/asset/images/no-data.svg" alt="no-data" className="img-fluid" />
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default TopCardIssuersoftheMonth;
